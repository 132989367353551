
<template>
  <div class="Chart">
    <base-echart :option="option"></base-echart>
  </div>
</template>

<script>
import BaseEchart from "@/components/base-charts";

export default {
  data () {
    return {
      xData: [],
      total: [],
      uv: [],
    };
  },
  components: {
    BaseEchart,
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    chartData: {
      handler (newData) {
        if (newData) {
          this.xData = newData.map(item => item.appName)
          this.total = newData.map(item => item.total)
          this.uv = newData.map(item => item.uv);

          this.option = this.getOption();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data () {
    return {
      option: {},
    };
  },
  methods: {
    getOption () {
      let option = {
        toolbox: {

        },
        backgroundColor: "",
        tooltip: {
          show: true,
          trigger: "axis",
          backgroundColor: '#fff',
          borderColor: "#999",
          textStyle: {
            color: "#212529",
          },
        },
        legend: {
          //data，就是取得每个series里面的name属性。
          orient: "horizontal",
          icon: "rect", //图例形状
          padding: 0,
          top: 15,
          right: 40,
          itemWidth: 20, //小圆点宽度
          itemHeight: 5, // 小圆点高度
          itemGap: 21, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
          textStyle: {
            fontSize: 14,
            color: "#212529",
          },
        },
        grid: {
          top: "10%",
          left: "8%",
          right: "8%",
          bottom: "10%",
          containLabel: false,
        },

        xAxis: {
          show: true, //显示x轴+x轴label文字
          type: "category",
          boundaryGap: false, //从Y轴出发，这个false很好的
          axisLine: {
            show: true, //显示x坐标轴轴线
            lineStyle: {
              color: "#E9EBF1",
              width: 1,
            },
          },

          axisTick: {
            show: false, //不显示x坐标1cm刻度
          },
          axisLabel: {
            color: "#212529", //x轴label文字颜色
          },
          splitLine: {
            show: true, //不显示grid竖向分割线
            lineStyle: {
              color: "#f3f4f8",
              type: "dashed",
            },
          },
          data: this.xData || ['10:00', '10:00', '10:00', '10:00', '10:00', '10:00']

        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#212529",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#E9EBF1",
              width: 1,
            },
          },
          splitLine: {
            show: true, //不显示grid水平分割线
            lineStyle: {
              color: "#f3f4f8",
              type: "dashed",
            },
          },
        },

        series: [
          {
            name: "UV",
            type: "line",
            showAllSymbol: true,
            symbol: "circle",
            symbolSize: 12,
            lineStyle: {
              normal: {
                color: "#7AA1FF",
              },
            },
            itemStyle: {
              color: "#7AA1FF",
              borderWidth: 3,
              borderColor: "#f3f3f3",
            },

            data: this.uv || [2, 12, 5, 36, 77, 33],
          },
          {
            name: "PV",
            type: "line",

            showAllSymbol: true,
            symbol: "circle",
            symbolSize: 12,
            lineStyle: {
              normal: {
                color: "#00ca95",
              },
            },
            itemStyle: {
              color: "#00ca95",
              borderColor: "#fff",
              borderWidth: 3,
            },

            data: this.total || [22, 52, 57, 56, 17, 73],
          },
        ],
      };
      return option;
    },
  },
};
</script>

<style lang="scss" scoped>
.Chart {
  height: 100%;
  width: 100%;
}
</style>
